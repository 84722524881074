<template>
<div id="Courses" class="grey lighten-2">
<v-container>

<!-- heading -->
<div class="titleDiv px-5">
  <p class="display-1">Courses Offered</p>
</div>

<!-- cards -->
<v-row v-show="GetCourses">
<v-col class="my-5" cols="12" sm="12" md="6" v-for="course in GetCourses" :key="course.courseslug" height="100%">
<v-fade-transition>
<v-card shaped class="grey lighten-2 d-flex flex-column animated fadeIn slower elevation-0" height="100%">

  <v-card shaped class="card-image-container elevation-5">
    <v-img class="card-image animated fadeIn slower" eager :src="course.imageUrl" aspect-ratio="1">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-card>
  <div class="card-strip grow"></div>

  <div class="card-primary-color elevation-5">

    <v-card-text class="card-outter">
      <v-card-subtitle class="subtitle-1">Eligibility : {{course.eligibility}}</v-card-subtitle> 
      <v-card-title class="title">{{course.title}}</v-card-title>
    </v-card-text>

    <v-card-actions class="card-actions">
      <v-btn class="action-btn elevation-5" @click="$router.push('/courseoverview/' + course.courseslug)">
        Explore<v-icon class="ml-2" dark>mdi-chevron-double-right</v-icon>
      </v-btn>
    </v-card-actions>
    
  </div>

</v-card>
</v-fade-transition>
</v-col>
</v-row>

<!-- placeholder -->
<div v-show="!GetCourses">
  <v-row>
    <v-col cols="12" sm="12" md="6" v-for="n in 6" :key="n">
     <v-skeleton-loader class="mx-auto" type="card, actions">
     </v-skeleton-loader>
    </v-col>
  </v-row>
</div>

</v-container>
</div>
</template>

<script>
export default {

    name: 'courselist',

    computed : {
      GetCourses(){
        let data =  this.$store.getters.GetAllCourses;
        return data != '' ? data : null;
      },
    },
}
</script>

<style scoped>
#Courses{
  padding-top: 7rem !important;
  padding-bottom: 5rem !important;
}
.titleDiv{
  border-left: 10px solid #000;
  margin-bottom: 2rem;
}
.card-image-container{
  height: 150px;
  width: 250px;
  margin-bottom: -75px
}
.card-image{
  height: 150px;
  width: 250px;
  
}
.card-outter {
  position: relative;
  padding-bottom: 80px;
  color: #eee;
}
.card-outter .subtitle-1{
  color: #ddd;
}
.card-actions {
  position: absolute;
  bottom: 0;
  padding: 20px;
  padding-left: 30px;
}
.card-actions .action-btn {
  background-color: #3b2060 !important;
  border: 1px solid #eee !important;
  color: #eee !important;
  margin-bottom: 10px;
}
.card-strip{
  background-color: #1A1043;
  padding: 1px 5px;
  height: 75px;
  border-top-right-radius: 20px;
}
.card-primary-color{
  background:linear-gradient(90deg, #3b2060 0%,#572289 100% );
}
</style>